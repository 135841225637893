<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Jurnal Akuntansi</strong>
      </template>
    <div class="row pl-3 pr-3 pt-3 pb-3">
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <label class="mt-1">Keterangan</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-input
              style="width: 500px;"
              v-model="input.keterangan"
              :readOnly="false"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="mt-1">Tanggal Transaksi</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <a-date-picker
              style="width: 125px;"
              v-model="input.tgl"
              :allowClear="false"
              format="DD-MM-YYYY"
            />
            <a-button
              class="btn btn-danger ml-2"
              v-if="selectedData.length !== 0"
              @click="removeFromGrid"
              >Remove Data</a-button
            >
            <!-- <a-button
              class="btn btn-outline-danger ml-2"
              v-if="selectedData.length !== 0"
              @click="bataledit"
              >Batalkan Edit</a-button
            > -->
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
        <div class="row">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <v-select
              ref="rekening"
              placeholder="Ketikan Rekening"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="maskInput.rekening"
              :options="masterRekening"
              :reduce="(tes) => tes.kode"
              @input="nextFocus('keteranganinput')"
            />
            <!-- <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <a-input class="" placeholder="No."></a-input>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              </div>
            </div> -->
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-input
              class=""
              placeholder="Keterangan"
              v-model="maskInput.keterangan"
              ref="keteranganinput"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <cleave
              ref="debet"
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.debet"
              placeholder="Debet"
              @keydown.native.enter="addDataToGrid"
            />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <cleave
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.kredit"
              @keydown.native.enter="addDataToGrid"
              placeholder="Kredit"
            />
          </div>
          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
            <a-button
              class="btn btn-outline-primary"
              v-if="selectedData.length !== 0"
              @click="addDataToGrid"
              >Simpan Perubahan</a-button
            >
            <a-button
              class="btn btn-outline-primary"
              v-else
              @click="addDataToGrid"
              >Tambahkan</a-button
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ag-grid-vue
          style="height: 41vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="false"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          rowSelection="single"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          @selection-changed="onSelectionChanged"
          @cell-value-changed="onCellValueChanged"
        ></ag-grid-vue>
        <a-button class="btn pull-right mt-3 btn-primary ml-2" @click="handleSubmit" v-if="selectedData.length === 0"
          >Simpan</a-button
        >
        <a-button v-if="action === 'update'" class="btn pull-right mt-3 btn-danger ml-2" @click="resetForm"
          >Cancel Update</a-button
        >
        <strong class="mt-4">Filter Tanggal</strong>
        <a-date-picker
          class="ml-4 mt-3"
          style="width: 150px;"
          v-model="tgl1"
          :allowClear="false"
          format="DD-MM-YYYY"
          @change="filterDate"
        />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
        <a-table
          :key="componentKeyDatatable"
          :columns="columns"
          :dataSource="data"
          size="small"
          :scroll="{ y: 400 }"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 500,
            hideOnSinglePage: true,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span v-if="column.dataIndex === 'name'">
                {{ text }}
              </span>
              <span v-else>
                {{ text }}
              </span>
            </template>
          </template>
          <template slot="cetak" slot-scope="text, record">
            <a-button
              class="btn btn-outline-success"
              @click="reGenerateValidasi(record)"
            >
              <a-icon type="printer" />
            </a-button>
          </template>
          <label slot="tgl" slot-scope="text">
            {{ formatTanggal(text) }}
          </label>
          <template slot="Nominal" slot-scope="text">
            {{ formatCurrency(text) }}
          </template>
          <template slot="menu" slot-scope="text, record">
            <a class="text-warning" @click="editData(record)">
              <a-icon class="mr-2" type="edit" />
            </a>
            <a-divider type="vertical" style="margin: 0px !important;"/>
            <a class="text-danger" @click="showDeleteConfirm(record.faktur)">
              <a-icon class="mr-2" type="delete" />
            </a>
            <a-divider type="vertical" style="margin: 0px !important;"/>
            <a-popover title="Details" placement="right">
              <template slot="content">
                <!-- <vue-custom-scrollbar style="max-height: 200px;width: auto;"> -->
                <table>
                  <tr>
                    <!-- <th style="padding: 0 10px;">Rekening</th> -->
                    <th style="padding-right: 10px;">Keterangan</th>
                    <th style="padding: 0 10px;">Debet</th>
                    <th style="padding-left: 10px;">Kredit</th>
                  </tr>
                  <tr v-for="(item, index) in record.details" :key="index">
                    <td style="padding-right: 10px;">{{ item.rekening + ' - ' + item.nama_rekening }}</td>
                    <td style="padding: 0 10px;">{{ formatCurrency(item.debet) }}</td>
                    <td style="padding-left: 10px;">{{ formatCurrency(item.kredit) }}</td>
                  </tr>
                  <tr>
                    <th style="padding-right: 10px;">Total</th>
                    <th style="padding: 0 10px;">{{ formatCurrency(getTotalDetails('debet', record.details)) }}</th>
                    <th style="padding-left: 10px;">{{ formatCurrency(getTotalDetails('kredit', record.details)) }}</th>
                  </tr>
                </table>
              <!-- </vue-custom-scrollbar> -->
              </template>
              <a href="javascript:void(0)">
                <a-icon class="mr-2 text-primary" type="info" />
              </a>
            </a-popover>
          </template>
          <template slot="jumlah" slot-scope="text, record">
            {{
              record.debet !== 0
                ? formatCurrency(record.debet)
                : formatCurrency(record.kredit)
            }}
          </template>
        </a-table>
      </div>
    </div>
    </a-card>
    <!-- <a-button
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              @click="addNullData"
            >
              Tambah
            </a-button> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import Cleave from 'vue-cleave-component'
import { Modal } from 'ant-design-vue'
// import vueCustomScrollbar from 'vue-custom-scrollbar'

import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // vueCustomScrollbar,
    cleave: Cleave,
  },
  created() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getMaster()
    this.getAllData()
  },
  data() {
    return {
      tgl1: moment(),
      masterRekening: [],
      action: 'create',
      editdata: {},
      maskInput: {
        id: 0,
        rekening: '',
        namarek: '',
        keterangan: '',
        debet: '',
        kredit: '',
      },
      input: {
        faktur: '',
        keterangan: '',
        tgl: moment(),
        data: [],
      },

      // Property a-table
      componentKeyDatatable: 0,
      columns: [
        {
          title: '#',
          width: 100,
          scopedSlots: {
            customRender: 'menu',
          },
        },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: { customRender: 'nomortransaksi' },
          width: 180,
        },
        {
          title: 'Tanggal',
          key: 'tgl',
          scopedSlots: { customRender: 'tgl' },
          width: 100,
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
        },
        {
          title: 'Total Debet',
          dataIndex: 'total_debet',
          width: 150,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Total Kredit',
          dataIndex: 'total_kredit',
          width: 150,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      // End of property

      // ag-grid-property
      changedData: [],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      selectedData: [],
      columnDefs: [],
      rowData: [],
      gridOptions: null,
      gridApi: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData() {
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var res = await lou.customUrlGet2('transaksi/jurnal' + imbuhan)
      if (res) {
        this.data = res.data
      }
    },
    nextFocus(to) {
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.click()
      // this.$refs.rekening.$el.blur()
      // console.log('to', to)
      setTimeout(() => {
        this.$refs[to].$el.focus()
      }, 200)
    },
    removeFromGrid() {
      var indexTarget = this.rowData.findIndex(
        (x) => x.uuid === this.selectedData[0].uuid,
      )
      this.rowData.splice(indexTarget, 1)
    },
    bataledit() {
      this.selectedData = []
      this.maskInput = {
        id: 0,
        rekening: '',
        namarek: '',
        keterangan: '',
        debet: '',
        kredit: '',
      }
    },
    addDataToGrid() {
      if (this.selectedData.length === 0) {
        var targetKer = this.masterRekening.findIndex((x) => x.kode === this.maskInput.rekening)
        var dataTarget = this.masterRekening[targetKer]
        var datafd = {
          uuid: this.$uuid.v4(),
          no: this.maskInput.no,
          id: dataTarget.id,
          rekening: dataTarget.kode,
          nama_rekening: dataTarget.keterangan,
          keterangan: this.maskInput.keterangan,
          debet: this.maskInput.debet !== '' ? this.maskInput.debet : '0',
          kredit: this.maskInput.kredit !== '' ? this.maskInput.kredit : '0',
        }
        this.rowData.splice(this.rowData.length - 1, 0, datafd)
        this.hitHitungTotal()
      } else {
        var target = this.rowData.findIndex((x) => x.uuid === this.selectedData[0].uuid)
        if (target !== -1) {
          var targetrek = this.masterRekening.findIndex(
            (x) => x.kode === this.maskInput.rekening,
          )
          var dataTargetRek = this.masterRekening[targetrek]
          var data = this.rowData[target]
          data.id = dataTargetRek.id
          data.rekening = dataTargetRek.kode
          data.nama_rekening = dataTargetRek.keterangan
          data.keterangan = this.maskInput.keterangan
          data.debet = this.maskInput.debet !== '' ? this.maskInput.debet : '0'
          data.kredit = this.maskInput.kredit !== '' ? this.maskInput.kredit : '0'
          this.selectedData = []
          this.componentKey += 1
          this.hitHitungTotal()
        }
      }
      this.selectedData = []
      this.maskInput = {
        id: 0,
        rekening: '',
        namarek: '',
        keterangan: '',
        debet: '',
        kredit: '',
      }
      const input = this.$refs.rekening.$el.querySelector('input')
      input.focus()
    },
    hitHitungTotal() {
      var target = this.rowData.findIndex(
        (x) => x.uuid === 'totaldarisemuanya',
      )
      // console.log('this.rowData.length', this.rowData.length)
      // console.log('this.rowData[this.rowData.length - 1]', this.rowData[this.rowData.length - 1])
      // this.rowData.splice(this.rowData.length - 1, 1)
      var totaldebet = 0
      var totalkredit = 0
      this.rowData.forEach((element) => {
        if (element.uuid !== 'totaldarisemuanya') {
          totaldebet += parseFloat(element.debet)
          totalkredit += parseFloat(element.kredit)
        }
        // console.log('element.debet', element.debet)
        // console.log('element.kredit', element.kredit)
      })
      this.rowData[target].debet = totaldebet
      this.rowData[target].kredit = totalkredit
      // this.rowData.push(
      //   {
      //     uuid: 'totaldarisemuanya',
      //     rekening: '',
      //     namarek: '',
      //     keterangan: 'Total',
      //     debet: totaldebet,
      //     kredit: totalkredit,
      //   },
      // )
    },
    async getMaster() {
      this.columnDefs = [
        // {
        //   headerName: 'No. ',
        //   field: 'no',
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        // },
        {
          headerName: 'Rek. Perkiraan',
          field: 'rekening',
          editable: false,
          resizable: true,
          sortable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: [],
            reffield: 'kode',
          },
        },
        {
          headerName: 'Nama Rek. Perkiraan',
          field: 'nama_rekening',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Keterangan Utk Buku Besar',
          field: 'keterangan',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Debet',
          field: 'debet',
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
        {
          headerName: 'Kredit',
          field: 'kredit',
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
      ]
      this.componentKey += 1
      var res = await lou.customUrlGet2('transaksi/jurnal/list_rekening')
      if (res) {
        this.masterRekening = []
        res.data.forEach((element) => {
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.masterRekening.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
      this.rowData.push({
        uuid: 'totaldarisemuanya',
        rekening: '',
        namarek: '',
        keterangan: 'Total',
        debet: 0,
        kredit: 0,
      })
    },
    async handleSubmit() {
      // console.log('this.rowData', this.rowData)
      if (this.rowData.length > 1) {
        // Modal.confirm({
        //   title: 'Apakah anda yakin data sudah benar?',
        //   content: 'Data tidak dapat di edit ataupun di hapus nanti!',
        //   okText: 'Yes',
        //   okType: 'danger',
        //   cancelText: 'No',
        //   onOk: async () => {
        var fd = this.$g.clone(this.input)
        fd.tgl = this.input.tgl.format('YYYY-MM-DD')
        var target = this.rowData.findIndex(
          (x) => x.uuid === 'totaldarisemuanya',
        )
        var datatotal = this.rowData[target]
        fd.totaldebet = datatotal.debet
        fd.totalkredit = datatotal.kredit
        // console.log('datatotal', datatotal)
        this.rowData.splice(this.rowData.length - 1, 1)
        fd.details = this.$g.clone(this.rowData)
        fd.details.forEach((element) => {
          delete element.uuid
          delete element.nama
          element.debet = parseFloat(element.debet)
          element.kredit = parseFloat(element.kredit)
        })
        fd.total_debet = fd.totaldebet
        fd.total_kredit = fd.totalkredit
        delete fd.totaldebet
        delete fd.totalkredit
        var res = null
        if (this.action === 'create') {
          delete fd.faktur
          res = await lou.customUrlPost2('transaksi/jurnal', fd)
        } else {
          delete fd.data
          res = await lou.customUrlPut2('transaksi/jurnal', fd)
        }
        if (res) {
          this.resetForm()
          this.getAllData()
        }
      //   },
      //   onCancel: () => {
      //     // console.log('Cancel')
      //     return false
      //   },
      // })
      } else {
        lou.shownotif('Peringatan!', 'Tidak ada data jurnal yang diinput.', 'error')
      }
    },

    resetForm() {
      this.editdata = {}
      this.action = 'create'
      this.selectedData = []
      this.maskInput = {
        id: 0,
        rekening: '',
        namarek: '',
        keterangan: '',
        debet: '',
        kredit: '',
      }
      this.input = {
        keterangan: '',
        tgl: moment(),
        data: [],
      }
      this.rowData = []
      // this.rowData.push({
      //   uuid: 'totaldarisemuanya',
      //   rekening: '',
      //   namarek: '',
      //   keterangan: 'Total',
      //   debet: 0,
      //   kredit: 0,
      // })
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.maskInput = selectedRows[0]
        this.maskInput.id = selectedRows[0].id
        this.maskInput.rekening = selectedRows[0].rekening
        this.maskInput.namarek = selectedRows[0].namarek
        this.maskInput.keterangan = selectedRows[0].keterangan
        this.maskInput.debet = selectedRows[0].debet
        this.maskInput.kredit = selectedRows[0].kredit
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    editData(record) {
      this.editdata = this.$g.clone(record)
      this.action = 'update'
      this.selectedData = []
      this.maskInput = {
        id: 0,
        rekening: '',
        namarek: '',
        keterangan: '',
        debet: '',
        kredit: '',
      }
      this.input = {
        faktur: this.editdata.faktur,
        keterangan: this.editdata.keterangan,
        tgl: moment(this.editdata.tgl, 'YYYY-MM-DD'),
        data: this.editdata.details,
      }
      this.rowData = this.editdata.details
      this.rowData.push({
        uuid: 'totaldarisemuanya',
        rekening: '',
        namarek: '',
        keterangan: 'Total',
        debet: 0,
        kredit: 0,
      })
      this.hitHitungTotal()
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/jurnal?faktur=' + deldata)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getTotalDetails(what, data) {
      var total = 0

      data.forEach(element => {
        total += element[what]
      })

      return total
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
